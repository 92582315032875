import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
const resolveWidgetAppForAccount = () => {
  const appAccount = process.env["REACT_APP_ACCOUNT_CODE"];
  if (!appAccount) {
    throw new Error(`Can't resolve widget without account`);
  }
  return `${appAccount}-widget`;
};

import(`./${resolveWidgetAppForAccount()}`)
  .then((module) => module.CarWidget)
  .then((CarWidget) => {
    ReactDOM.render(<CarWidget />,
      document.getElementById("car_widget")
    );
  });
